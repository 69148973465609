import { AskRequest, AskResponse, ChatRequest } from "./models";
import axios from "axios";

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch("/ask", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                retrieval_mode: options.overrides?.retrievalMode,
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<Response> {
    const newUrl = "https://jkfenner-backend-app.azurewebsites.net";
    const url = options.shouldStream ? "/chat_stream" : "/chat";
    const ipAddress = async () => {
        try {
            const res = await axios.get("https://api.ipify.org/?format=json");
            return res.data.ip;
        } catch (error) {
            console.error("Error fetching IP address:", error);
            return null; // Return a default value or handle the error as needed
        }
    };
    const ip = await ipAddress();
    return await fetch(newUrl + url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            ipaddress: ip
            // approach: options.approach,
            // overrides: {
            //     retrieval_mode: options.overrides?.retrievalMode,
            //     semantic_ranker: options.overrides?.semanticRanker,
            //     semantic_captions: options.overrides?.semanticCaptions,
            //     top: options.overrides?.top,
            //     temperature: options.overrides?.temperature,
            //     prompt_template: options.overrides?.promptTemplate,
            //     prompt_template_prefix: options.overrides?.promptTemplatePrefix,
            //     prompt_template_suffix: options.overrides?.promptTemplateSuffix,
            //     exclude_category: options.overrides?.excludeCategory,
            //     suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            // }
        })
    });
}

export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}
