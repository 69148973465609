import React, { useEffect, useState } from "react";
// import { arrowExit20Regular, IconButton } from '@fluentui/react-icons';
import { Stack, IconButton } from "@fluentui/react";
import { Outlet, NavLink, Link, useNavigate } from "react-router-dom";
import github from "../../assets/github.svg";
import HRAssistantLogo from "../hr-assistant-logo-transparent.png";

import styles from "./Layout.module.css";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <img src={HRAssistantLogo} className={styles.logo} alt="logo" />
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>HR Assistant - Demo</h3>
                    </Link>
                    <nav>
                        {/* <ul className={styles.headerNavList}>
                        <li>
                            <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                Chat
                            </NavLink>
                        </li>
                        
                    </ul> */}
                    </nav>
                </div>
                {/* <div className={styles.logout}>
            <IconButton
            onClick={() => logoutFunction()}
            className={styles.logoutIcon}
                            style={{ color: "white" }}
                            iconProps={{ iconName: "SignOut" }}
                            title="log out"
                            ariaLabel="logout"
                           
                        />
            <button className={styles.logoutbutton} type="button" onClick={() => logoutFunction()}></button>
            </div> */}
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
